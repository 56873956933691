const toVariable = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const customProperty = { [`--${key}`]: obj[key] }

    return { ...acc, ...customProperty }
  }, {})
}

const addVariablesInBody = (cssVariables) => {
  Object.keys(cssVariables).forEach((property) => {
    document.documentElement.style.setProperty(property, cssVariables[property])
  })
}

export default {
  addVariablesInBody,
  toVariable,
}
