import { navigate } from "gatsby"
import { EXTERNAL_LINKS, URL_PATH_PREFFIX } from "gatsby-env-variables"

export const isClient = () =>
	!!(typeof window !== "undefined" && window.document && window.document.createElement)

export const resetFocus = () => {
	const isClient = typeof window !== "undefined"
	if (!isClient) return
	const firstLink = document.querySelector(".ds-header__logo > a")
	if (firstLink) firstLink.focus()
}

export const getGoogleIdFormatted = (cookie) => {
	if (!cookie) return ""
	const cookieSplitted = cookie.split(".")
	return `${cookieSplitted[2]}.${cookieSplitted[3]}`
}

export const getCookies = () => {
	return document.cookie.split("; ").reduce((prev, current) => {
		const [name, ...value] = current.split("=")
		prev[name] = value.join("=")
		return prev
	}, {})
}

export const addScript = (url, attributes = [], appendOnBody = true) => {
	let SCRIPTS_LOADING = {}
	const createScript = (resolve) => {
		SCRIPTS_LOADING[url] = true
		const script = document.createElement("script")
		script.src = url
		script.async = true
		script.onload = () => {
			SCRIPTS_LOADING[url] = false
			resolve()
		}

		attributes.forEach(({ key, value }) => {
			script[key] = value
		})

		return script
	}

	return new Promise((resolve) => {
		const query = document.querySelector(`script[src="${url}"`)

		if (query) {
			const prevCallback = query.onload
			if (SCRIPTS_LOADING[url] && prevCallback) {
				query.onload = () => {
					prevCallback()
					resolve()
				}
			} else {
				resolve()
			}
		} else {
			SCRIPTS_LOADING[url] = true
			const script = createScript(resolve)
			if (appendOnBody) {
				document.body.appendChild(script)
			} else {
				document.head.appendChild(script)
			}
		}
	})
}

export const queryParse = (queryString = "") => {
	if (!queryString) return {}
	const search = queryString.charAt(0) === "?" ? queryString.split("?").pop() : queryString
	const searchChunks = search.split("&")
	const queryStringObj = {}

	searchChunks.forEach((chunk) => {
		const [key, value] = chunk.split("=")
		if (queryString[key] !== undefined) return
		queryStringObj[key] = value
	})

	return queryStringObj
}

export const queryStringfy = (params = {}) => {
	if (Object.keys(params).length === 0) return ""
	return Object.keys(params)
		.map((key, index) => {
			if (!index) {
				return `?${key + "=" + params[key]}`
			} else {
				return key + "=" + params[key]
			}
		})
		.join("&")
}

export const getUrlParamAll = () => {
	if (!isClient()) return {}
	const searchRaw = window.location.search
	const query = queryParse(searchRaw)
	return query
}

export const getUrlParam = (keyToFind) => {
	if (!window?.location?.search) return
	const locationSearch = window.location.search.split("?").pop()
	let valueToRetrieve

	locationSearch.split("&").forEach((keyValue) => {
		const [key, value] = keyValue.split("=")
		if (key === keyToFind) {
			valueToRetrieve = value
			return
		}
	})

	return valueToRetrieve
}

export const navigateWithQueryString = ({
	path = isClient() ? window.location.pathname : "",
	query = {},
	ignoreActualQuery = false,
	replace = false
}) => {
	if (!isClient()) return

	const externalLinkRegex = /^https?:\/\//

	const actualQuery = getUrlParamAll()

	const [pathToNavigate, pathQueryString] = path.split("?")

	const pathQuery = queryParse(pathQueryString)

	const queryUpdated = queryStringfy({
		...(ignoreActualQuery ? {} : actualQuery),
		...pathQuery,
		...query
	})

	const isExternalLink = externalLinkRegex.test(pathToNavigate)

	const preffix = isExternalLink ? "" : URL_PATH_PREFFIX ?? ""

	if (replace) {
		window.location.replace(`${preffix}${pathToNavigate}${queryUpdated}`)
		return
	}

	window.location.href = `${preffix}${pathToNavigate}${queryUpdated}`
}

export const gatsbyNavigateWithQueryString = ({
	path = isClient() ? window.location.pathname : "",
	query = {},
	ignoreActualQuery = false,
	replace = false
}) => {
	if (!isClient()) return

	const externalLinkRegex = /^https?:\/\//

	const actualQuery = getUrlParamAll()

	const [pathToNavigate, pathQueryString] = path.split("?")

	const pathQuery = queryParse(pathQueryString)

	const queryUpdated = queryStringfy({
		...(ignoreActualQuery ? {} : actualQuery),
		...pathQuery,
		...query
	})

	const isExternalLink = externalLinkRegex.test(pathToNavigate)

	const preffix = isExternalLink ? "" : URL_PATH_PREFFIX ?? ""

	navigate(`${preffix}${pathToNavigate}${queryUpdated}`, { state, replace })
}

export const getLinkWithActualQueryString = (link) => {
	const allParams = getUrlParamAll()
	return `${link}${queryStringfy(allParams)}`
}

export const getUUIDFromUrl = () => {
	const params = getUrlParamAll()
	const keys = {
		lead: "leadUUID",
		simulation: "simulationUUID",
		financing: "financingUUID"
	}
	const getValue = (name) => {
		return { name: name, value: params[name] }
	}

	if (params[keys.lead]) return getValue(keys.lead)
	if (params[keys.simulation]) return getValue(keys.simulation)
	if (params[keys.financing]) return getValue(keys.financing)
}

export const renewTokenTime = (validTime = 86400, serverTime = 0) => {
	const renewSeconds = validTime - serverTime
	return renewSeconds * 1000 + Date.now()
}

export const clearQueryStringFromUrl = (param) => {
	if (!window?.location?.search) return
	if (window.history.replaceState) {
		let newQueryString = ""
		if (param) {
			const allParams = getUrlParamAll()
			delete allParams[param]
			newQueryString = queryStringfy(allParams)
		}
		const queryString = window.location.href.split("?").pop()
		const newUrl = window.location.href.replace(`?${queryString}`, newQueryString)
		window.history.replaceState({}, document.title, newUrl)
	}
}

export const removeMultipleSpaces = (value) => {
	if (value === undefined) return undefined
	if (!value) return ""
	const splittedValue = value.split(" ")
	return splittedValue.reduce((acc, curr) => `${acc} ${curr}`.trim(), "")
}

export const numberFormatter = ({
	style = "decimal",
	value = 0,
	currency = "BRL",
	decimals = 2,
	currencyDisplay = "symbol"
}) => {
	return Intl.NumberFormat("pt-BR", {
		style,
		currency,
		currencyDisplay,
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals
	}).format(value)
}

export const getCookie = (cookieName) => {
	if (!isClient()) {
		return ""
	}

	const name = `${cookieName}=`
	const decodedCookie = decodeURIComponent(document.cookie)
	let cookieList = decodedCookie.split(";")
	for (let i = 0; i < cookieList.length; i++) {
		let singleCookie = cookieList[i]
		while (singleCookie.charAt(0) === " ") {
			singleCookie = singleCookie.substring(1)
		}
		if (singleCookie.indexOf(name) === 0) {
			return singleCookie.substring(name.length, singleCookie.length)
		}
	}
	return ""
}

export const returnToSimulationPage = () => {
	navigateWithQueryString({ path: `${EXTERNAL_LINKS.BASE_URL}/simulacao`, isExternal: true })
}
