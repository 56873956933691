import { isClient } from "@utils/browser"
import { withPrefix } from "gatsby-link"

const S3_DOMAIN = "https://s3.amazonaws.com/a.storyblok.com"
const originUrlBrowser = isClient() && window.location.origin

const fontsOrigin = ({ originFonts, assetsPathFonts }) => {
  const fonts = {
    intern: {
      ItauDisplay_300: `${S3_DOMAIN}/f/142523/x/eddaecf108/itaudisplay_300.woff2`,
      ItauDisplay_700: `${S3_DOMAIN}/f/142523/x/ad4050764d/itaudisplay_700.woff2`,
      ItauDisplay_900: `${S3_DOMAIN}/f/142523/x/735862fe51/itaudisplay_900.woff2`,
      ItauText_300: `${S3_DOMAIN}/f/142523/x/8283f3901b/itautext_300_new.woff2`,
      ItauText_400: `${S3_DOMAIN}/f/142523/x/f6c96aad5c/itautext_400_new.woff2`,
      ItauText_700: `${S3_DOMAIN}/f/142523/x/bed131e8ff/itautext_700_new.woff2`,
      ItauText_900: `${S3_DOMAIN}/f/142523/x/b1010369cf/itautext_900_new.woff2`,
    },
    extern: {
      ItauDisplay_300: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/ItauDisplay_300.woff2"
      )}`,
      ItauDisplay_700: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/ItauDisplay_700.woff2"
      )}`,
      ItauDisplay_900: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/ItauDisplay_900.woff2"
      )}`,
      ItauText_300: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/itautext_300_new.woff2"
      )}`,
      ItauText_400: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/itautext_400_new.woff2"
      )}`,
      ItauText_700: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/itautext_700_new.woff2"
      )}`,
      ItauText_900: `${originUrlBrowser}${assetsPathFonts}${withPrefix(
        "fonts/itautext_900_new.woff2"
      )}`,
    },
  }
  return fonts[originFonts]
}

const configureFonts = ({ fonts, assetsPathFonts, originFonts, fontDisplay }) => {
  const allFontsPromise = Object.keys(fonts).map((fontName) => {
    const weights = fonts[fontName]

    const promises = weights.map((weight) => {
      const fontUrl = fontsOrigin({ originFonts, assetsPathFonts })[
        `${fontName}_${weight}`
      ]

      const font = new FontFace(fontName, `url(${fontUrl})`, {
        style: "normal",
        weight: weight,
        display: fontDisplay,
      })

      return font.load().then(() => {
        document.fonts.add(font)
      })
    })

    return Promise.all(promises)
  })

  return Promise.all(allFontsPromise)
}

export default configureFonts
