import colors from "./colors.json"
import variant from "./variant.json"
import border from "./border.json"
import shadow from "./shadow.json"
import spacing from "./spacing.json"
import typography from "./typography.json"

export default {
  colors,
  variant,
  border,
  shadow,
  spacing,
  typography,
}
