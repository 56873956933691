import configureTokens, { getTokens } from "./tokens"
import configureFonts from "./fonts"

const configure = ({
  originFonts = "extern",
  fonts = {
    ItauText: [300, 400, 700, 900],
    ItauDisplay: [300, 700, 900],
  },
  fontDisplay = 'auto',
} = {}) => {

  configureTokens({ app: "itau", variant: "varejo" }).then(() => {
    console.log("%c tokens loaded", "color: #EC7000")
  })

  configureFonts({ fonts, assetsPathFonts: "", originFonts, fontDisplay }).then(() => {
    console.log("%c fonts loaded", "color: #EC7000")
  })
}

export { getTokens }
export default configure
