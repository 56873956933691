import css from "src/utils/css"
import allTokens from "../tokens"

const getCssVariables = (tokens, variant) => {
	return Object.keys(tokens).reduce((acc, key) => {
		if (key === "variant" && variant) {
			const variantVariables = tokens[key][variant]
			const variables = css.toVariable(variantVariables)
			return { ...acc, ...variables }
		}

		const variables = css.toVariable(tokens[key])

		return { ...acc, ...variables }
	}, {})
}

export const getTokens = ({ app, variant }) => {
	const tokens = allTokens[app]
	return getCssVariables(tokens, variant)
}

const configureTokens = ({ app, variant }) => {
	const cssVariables = getTokens({ app, variant })
	css.addVariablesInBody(cssVariables)

	return Promise.resolve()
}

export default configureTokens
